import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from '../utils/footer';
import { Webservice } from '../services/index';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from '../utils/constants';
type FormElem = React.FormEvent<HTMLFormElement>;

const useStyles = makeStyles(theme => ({
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '25ch'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  statusLabel: {
    color: '#ff1234'
  },
  logout: {
    display: 'block',
    textAlign: 'center'
  },
  fullWidth: {
    width: '100%'
  }
}));

function Redeem() {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [status, setStatus] = useState<string>('');
  const [id] = useState<string>('');
  const [showRedeem, setShowRedeem] = useState<boolean>(false);
  const [getFile, setFile] = useState<any>('');
  const [resMsg, setResMsg] = useState('');

  const [message, setMessage] = useState('');

  let data = new FormData();

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    console.log('Use Effect');
  });

  const redeemCode = async () => {
    await Webservice.updateCode(id);
    setShowRedeem(false);
    setStatus('');
    setCode('');
  };

  const handleUploadClick = (event: any) => {
    setFile('');
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (e: FormElem) => {
    e.preventDefault();
    data.append('csvFile', getFile);
    data.append('msg', message);

    axios
      .post(`${apiUrl}/sms`, data, {
        headers: {
          'x-access-token': `${localStorage.getItem('token')}`
        }
      })
      .then(res => {
        // then print response status
        console.log(res);
        console.log(res.data.msg);
        setResMsg(res.data.msg);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sent SMS
        </Typography>
        <form onSubmit={handleSubmit}>
          <input
            className="fullWidth "
            accept="file/*"
            type="file"
            id="code"
            name="code"
            value={code}
            onChange={e => handleUploadClick(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="standard-multiline-flexible"
            label="Message"
            multiline
            rowsMax={4}
            value={message}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send
          </Button>
          <Typography component="h1" variant="h5">
            {resMsg}
          </Typography>
          {showRedeem ? (
            <Box component="span" m={0}>
              Do yo want redeem?
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={redeemCode}
              >
                Redeem
              </Button>
            </Box>
          ) : (
            ''
          )}
        </form>
        <Box component="span" m={1} className={classes.statusLabel}>
          {status}
        </Box>
      </div>

      <Box mt={8}>
        <Copyright />
      </Box>
      <Box component="span" m={1} className={classes.logout}>
        <Link to="/">Logout</Link>
      </Box>
    </Container>
  );
}

export default Redeem;
